<template>
    <div class="signSysMsg">
        <div class="main">
            <div class="main-left">
                <el-row>
                    <el-col :span="12">
                        <div>
                            <label class="color-gray">发布人：</label>
                            <span>{{ msgDetail.dispatcher }}</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <label class="color-gray">发布时间：</label>
                            <span>{{ msgDetail.createtime }}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="content-h">
                    <el-col :span="24" class="content-c">
                        <div class="content">
                            <label class="fl tit color-gray">发布正文：</label>
                            <!-- eslint-disable-next-line vue/no-v-html -->
                            <div class="fl txt" v-html="msgDetail.content"></div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="main-right">
                <div class="file-con">
                    <div class="tit clearfix">
                        <span class="fl color-gray">全部资料</span>
                        <!-- <a href="javascript:;" class="fr">下载全部</a> -->
                    </div>
                    <div class="upload-list">
                        <ul>
                            <li v-for="item in msgDetail.enclist" :key="item.keyid" @click="download(item)">
                                <span class="iconfont iconwenjian"></span>
                                <span class="upload-list-down">{{ item.filename }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="sign-con">
                    <div class="color-gray">
                        签收意见
                    </div>
                    <div class="clearfix sign-list">
                        <div v-for="(item, index) in msgDetail.signlist" :key="`sign${index}`" class="item">
                            <div class="fl head-icon">
                                {{ item.user_name }}
                            </div>
                            <div class="fl sign-text">
                                <div class="color-gray">
                                    {{ item.opinion_time }}
                                </div>
                                <div>{{ item.opinion }}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <textarea class="sign-area" v-model="signData.Content" placeholder="请填写签收意见"></textarea>
                    </div>
                    <div class="sign-btn">
                        <el-button type="primary" class="fr" @click="sign">
                            签收
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import jwtDecode from 'jwt-decode';
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            id: '',
            msgDetail: {

            },
            signData: {
                Id: '',
                Content: '',
            },
            user: '',
        };
    },
    watch: {},
    computed: {},
    methods: {
        getDetail() {
            this.$axios
                .get('/interfaceApi/message/MessageInfo/get_detail/' + this.id)
                .then(res => {
                    this.msgDetail = res;
                }).catch(err => {
                    this.$message.error(err.message);
                });
        },
        sign() {
            this.$axios
                .post('/interfaceApi/message/MessageInfo/sign', this.signData)
                .then(res => {
                    this.$message.success(res);
                    this.$parent.$emit('close');
                }).catch(err => {
                    this.$message.error(err.message);
                });
        },
        // 文件下载
        download(data) {
            // window.location.href = this.FILE.FILE_BASE_URL() + 'file/' + data.keyid + '/0';
            this.getFileDetailFun(data.keyid);
        },
        getFileDetailFun(id, insertImg) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + id)
                .then(res => {
                    window.location.href = res.download_url;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        const token = window.sessionStorage.getItem('token');
        // 解析token，获取token中数据
        const decode = jwtDecode(token);
        this.user = decode.Name;
        this.id = this.extr.id;
        this.getDetail();
        this.signData.Id = this.id;
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.signSysMsg
    font-size .15rem
    overflow hidden
    height 100%
    .main
        height 100%
        .main-left
            width 70%
            float left
            border-right 1px solid #eee
            height 100%
            padding .15rem
            .content-h
                height calc(100% - .4rem)
                .content-c
                    height 100%
                    .content
                        height 100%
                        .tit
                            width .86rem
                        .txt
                            width calc(100% - .86rem)
                            height 100%
                            overflow-y auto
                            overflow-x hidden

        .main-right
            height 100%
            width 30%
            float left
            padding .15rem
            .file-con
                width 100%
                height 40%
                .tit
                    a
                        color #47a2fd
                .upload-list
                    width 100%
                    height: calc(100% - .20rem);
                    overflow-y: auto;
                    overflow-x: hidden;
                    ul
                        li
                            border-bottom 1px solid #eee
                            line-height: .32rem;
                            cursor pointer
                            &:last-child
                                border-bottom none
                            .iconfont
                                font-size 0.16rem
                                color #1296db
                                margin-right 10px
                                line-height .16rem
                        .upload-list-down
                            text-decoration underline
            .sign-con
                width 100%
                overflow hidden
                height 60%
                .sign-list
                    margin-top .10rem
                    height calc(100% - 2.4rem)
                    overflow-y auto
                    overflow-x hidden
                    .item
                        overflow hidden
                        .head-icon
                            width .40rem
                            height .40rem
                            text-align center
                            line-height .40rem
                            background #15a9fe
                            border-radius 50%
                            color #fff
                        .sign-text
                            margin-left .10rem
                .sign-area
                    height 1.40rem
                    border 1px solid #f1f1f1
                    width 100%
                    padding .10rem
                    // margin-top .50rem
                .sign-btn
                    margin-top .1rem
                    overflow hidden
                    .el-button
                            padding .12rem .20rem

.el-row
    margin-bottom .20rem
    &:last-child
      margin-bottom 0
.color-gray
    color #87868b

</style>